@import "mixins"
.button
  padding: 0 8px
  @include flex
  justify-content: center
  line-height: 12px
  font-size: 12px
  border: 0
  color: var(--button_color)
  @include bold
  border-radius: var(--base_borderRadius)
  height: 36px
  min-width: 90px
  text-transform: uppercase

.primary
  background: rgb(var(--particularColor))

  &:active
    background: var(--button_primaryActiveBg)

.secondary
  background: #dfe6f3
  color: var(--txt_color)

  &:active
    background: #A7B6D3

.darkGreyBtn
  background: #dfe6f3
  color: var(--txt_color)

  &:active
    background: #A7B6D3

.greenBtn
  background: var(--button_greenBg)
  color: var(--button_greenColor)

  &:active
    background: var(--button_greenActiveBg)

.disabled
  opacity: 0.5
  pointer-events: none
