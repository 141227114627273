@import "mixins"
.bannerModal
  :global(.modal)
    max-width: 90%
    padding: 16px 0
    background: none
    box-shadow: none

.banners
  @include flex
  flex-direction: column
  justify-content: center
  min-height: 100%
  max-width: 100%

.close
  width: 40px
  height: 40px
  border-radius: 30px
  background: #000
  border: 1px solid #1d1d1d
  @include flex
  justify-content: center
  position: absolute
  top: 0
  right: 0
  z-index: 2
  > span
    @include flex
  svg
    opacity: 0.5
    width: 14px
    height: 14px
    path
      fill: #fff
  &:active svg
    opacity: 0.8

.bannerItem
  @include flex
  overflow: hidden
  border-radius: 20px
  position: relative
  box-shadow: 0 0 10px rgba(#000, 0.5)
  max-width: 100%

  &:not(:first-of-type)
    margin-top: 16px
  > a, > button
    @include flex
    height: auto
    padding: 0
    border-radius: unset
    background: var(--preload_bg)
  img
    max-width: 100%
