@import "mixins"
.block
  background: var(--betslip_historyBetHeaderBg)
  border: 1px solid var(--betslip_historyBetBorder)
  border-radius: 4px
  margin-bottom: 10px
  overflow: hidden
  max-height: 48px
  transition: max-height .4s
  &.opened
    max-height: 500px

.verifiedView
  @include flex
  padding: 6px 6px 6px 10px
  min-height: 46px

  > span
    @include flex
    @include fadeIn(.7s)

    svg
      width: 18px
      height: 14px

    &.iconVerified
      margin: 0 8px
      path
        fill: #5cba60

.userInfo
  @include flex
  flex: 1
  @include bold
  font-size: 14px
  color: rgb(var(--secondaryColor))
  letter-spacing: 1px

  > span
    @include flex
    @include fadeIn(.7s)
    margin-right: 10px

    svg
      width: 20px
      height: auto
      path
        fill: var(--menu_betslipIconTopColor)

.verifyBtn
  font-size: 14px
  background: var(--acount_verifyBtnBg)
  color: rgb(var(--secondaryColor))
  &:active
    background: var(--acount_verifyBtnActive)

.verifyForm
  padding: 10px 10px 10px 6px
  background: var(--betslip_itemBg)
  border-top: 1px solid var(--betslip_historyBetBorder)
  font-size: 14px

  button
    min-width: 130px
    font-size: 14px

.verificationTxt
  line-height: 18px
  color: rgb(var(--secondaryColor))

.verifyFormFields
  @include flex
  margin-top: 12px
  > div
    flex: 1
    margin-right: 10px
    label
      font-size: 14px
      text-transform: capitalize
      margin-bottom: 8px

    input:disabled
      opacity: .6

  button
    margin-top: 22px

  .verifyBtn
    background: #5cba60

    &:active
      background: #3fa143

.wrongTxt
  color: rgb(var(--particularColor2))
  margin: 4px 0

.resendTxt
  color: rgb(var(--secondaryColor))
  margin: 6px 0 0
