@import "mixins"
.header
  background: var(--header_bg)
  height: var(--headerHeight)
  box-shadow: var(--header_shadow)
  @include flex
  position: fixed
  top: 0
  width: 100%
  z-index: 15
  padding-right: 10px

  > a
    margin-right: auto
    min-width: 60px
    @include flex
    padding-right: 6px

  .registerBtn
    height: var(--header_balanceHeight)

  .signupActive
    background: #1d2329
    box-shadow: 0 -1px rgb(var(--particularColor))
    height: 40px
    border-bottom-left-radius: 0
    border-bottom-right-radius: 0
    margin-top: 6px

.collapseBtn
  width: 39px
  min-width: 39px
  height: 100%
  border-radius: 0
  flex-direction: column
  justify-content: center
  margin-right: 10px
  position: relative
  background: transparent

  > span
    width: 13px
    height: 2px
    background: var(--header_collapseBtnLinesBg)
    transition: margin 0.3s

    &:nth-child(2)
      margin: 4px 0 4px -6px

  &.opened
    background: var(--header_collapseBtnOpenedBg)

    > span:nth-child(2)
      margin-left: 6px

.customDropdown
  margin-right: 4px
  position: relative
  margin-left: 8px
  max-width: 150px
  min-width: 132px
  flex: 1

  .bonusLabel
    position: absolute
    left: 0
    top: 0

  .typeBonus
    margin-top: 4px

  > button
    background: var(--header_balanceBg)
    border: 1px solid var(--header_balanceBorder)
    color: rgb(var(--secondaryColor))
    box-shadow: var(--header_balanceShadow)
    width: 100%
    height: var(--header_balanceHeight)
    position: relative
    overflow: hidden

    .typeBonus
      margin-left: 4px

    :global(.crypto-currency)
      margin-right: 8px

    > div:not(.loader)
      display: flex
      align-items: flex-end
      flex-direction: column
      text-align: right
      margin-left: auto
      letter-spacing: 1px
      font-size: 13px
      padding-top: 2px
      font-weight: normal

      > span:nth-child(2)
        display: block
        color: rgba(var(--primaryColor), 0.8)
        margin-top: 1px
        font-size: 11px

    &:before
      content: ""
      position: absolute
      display: inline-block
      width: 0
      height: 0
      border-style: solid
      border-width: 8px 0 0 8px
      border-color: transparent transparent transparent var(--header_balanceTriangle)
      left: 0
      bottom: 0

    &.opened
      background: var(--header_balanceOpenedBg)
      border-color: var(--header_balanceOpenedBorder)

      &:after
        transform: rotate(180deg)

.dropdownList
  position: absolute
  left: unset
  right: 0
  z-index: 10
  background: var(--header_balanceDdlBg)
  border-radius: 3px
  min-width: calc(100% + 74px)
  width: auto
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4)
  margin: 3px 0 0
  overflow-x: hidden
  max-height: 70vh
  overflow-y: auto

  li
    height: 40px
    @include flex
    padding: 0 10px
    font-size: 12px
    position: relative

    :global(.crypto-currency)
      margin-right: 8px
      min-width: 16px

      svg
        width: 16px
        height: 16px

    > div
      display: flex
      justify-content: center
      flex-direction: column
      flex: 1

      > span
        text-transform: uppercase
        color: rgb(var(--secondaryColor))
        @include bold
        @include flex
        justify-content: space-between
        margin: 3px 0

        span
          font-weight: normal
          margin-left: 10px
          color: rgba(var(--secondaryColor), 0.9)

    &:not(:first-of-type)
      border-top: 1px solid var(--header_balanceDdlBorderTop)

    .currency
      @include flex
      justify-content: space-between

      > span:not(.bonusLabel)
        font-size: 11px
        position: relative
        @include flex

        i
          @include flex
          flex-direction: column
          margin-right: 4px
          height: 7px

          &:before,
          &:after
            content: "\223C"
            height: 3px
            line-height: 3px

        > span
          color: inherit

      > span:not(.bonusLabel) :global
        .loader
          position: static
          background: transparent

          > span
            font-size: 5px

    &.selected
      background: var(--header_balanceDdlSelectedBg)
      box-shadow: inset 1px 0 var(--header_balanceDdlSelectedShadow),inset -1px 0 var(--header_balanceDdlSelectedShadow)

      div
        > span > span
          color: rgb(var(--secondaryColor))

.bonusWallet
  background: #171b20
  color: rgb(var(--thirdaryColor))
  padding: 2px 4px 1px
  margin-left: -4px
  border-radius: 2px
  display: inline-block

.walletButton
  @include flex
  justify-content: center
  min-width: 44px
  width: 44px
  height: var(--header_balanceHeight)
  border: 1px solid var(--header_balanceBorder)
  box-shadow: var(--header_balanceShadow)
  background: var(--header_walletBtnBg)

.walletIcon svg path:first-of-type
  fill: var(--header_walletIconBg)

.walletButtonActive
  background: var(--walletBtnActiveBg)
  height: 29px
  width: 120px
  @include flex
  padding: 0 14px 2px
  justify-content: space-between
  box-shadow: 0 -1px rgb(var(--particularColor))
  border-radius: 5px 5px 0 0
  position: absolute
  top: -29px
  right: 8px
  z-index: 1
  :global(.svg-icon svg)
    width: 10px
    height: 10px

.walletModal
  height: calc(100% - 35px)
  top: 35px
  z-index: 1001

  > div:first-of-type
    position: fixed

  :global(.modal)
    box-shadow: 8px 0 4px -4px rgba(0, 0, 0, 0.2), -8px 0 4px -4px rgba(0, 0, 0, 0.2),0 -8px 4px -4px rgba(0, 0, 0, 0.2)
    overflow: visible

.walletModalContainer
  background: var(--wallet_bg)
  line-height: normal
  height: 100%

  > div
    height: 100%
    overflow: auto

    > div
      display: flex
      flex-direction: column
      height: unset
      min-height: 100%

.walletPixModal
  z-index: 1001
  align-items: flex-start

  :global(.modal)
    width: 100%
    max-width: 428px
    border-radius: 8px
    background: var(--walletPix_modalBg)
    height: 640px
    max-height: calc(100% - 26px)
    top: 50%
    transform: translateY(-50%)
    font-family: var(--roboto-font)
    overflow: unset

    .closeWalletPix
      @include flex
      justify-content: center
      position: absolute
      right: 0
      z-index: 2
      width: 44px
      height: 44px
      border-top-right-radius: 8px
      background: var(--walletPix_modalCloseBg)
      top: 0

      span
        @include flex

      svg
        width: 20px
        height: 20px

      &:active svg
        opacity: 0.8

.walletTolaModal :global(.modal)
  width: 96%
  max-width: 440px
  border-radius: 8px
  background: var(--walletPix_modalBg)
  max-height: 96%
  height: auto
  font-family: var(--roboto-font)
  > i
    width: 44px
    height: 44px
    border-radius: 0
    background: var(--walletPix_fieldBg)
    top: 0
    right: 0
    &:before
      mask-size: 20px
      width: 20px
      height: 20px

.signupCompact :global(.modal)
  height: auto
  max-height: 100%
  width: 100%
  border-radius: 8px 8px 0 0

  :global(.loader)
    position: static
    min-height: 418px

  > i
    right: 10px
    top: 12px
    width: 34px
    height: 34px
    border-radius: 8px

    &:before
      transform: scale(1.2)

    &:active:before
      opacity: 0.8

  > section
    padding: 10px 8px

.signupDefault :global(.modal)
  :global(.loader)
    min-height: 564px

.signupCpf :global(.modal)
  > i
    right: 10px
    top: 8px
  > section
    padding: 0

  :global(.loader)
    min-height: 414px

.signupAfro :global(.modal)
  > i
    right: 10px
    top: 8px
  > section
    padding: 0

  :global(.loader)
    min-height: 562px

.inactivityModal :global(.modal)
  display: flex
  flex-direction: column
  padding: 20px 16px
  height: auto
  min-height: 200px
  border-radius: 8px
  max-width: 400px
  > p
    margin: auto 0
    line-height: 18px
    text-align: center
    font-size: 14px
    color: rgb(var(--secondaryColor))
  > div
    @include flex
    justify-content: center
    margin-top: 20px
    > button
      flex: 1
      &:nth-child(2)
        margin-left: 12px

.loader
  > span
    font-size: 15px !important

@media screen and (max-width: 320px) and (orientation: portrait)
  .walletButton
    min-width: 38px
    width: 38px
    padding: 0

  .customDropdown
    margin-left: 4px

  .dropdownList
    min-width: calc(100% + 40px)

    li
      padding: 0 6px

  .registerBtn
    padding: 0 4px
    min-width: auto
    font-size: 11px

  .collapseBtn
    margin-right: 6px
    width: 30px
    min-width: 30px

    > span
      width: 10px
