@import "mixins"
.modalContainer
  width: 100%
  height: calc(100% - var(--headerHeight) - var(--menuHeight))
  position: fixed
  z-index: 10
  top: var(--headerHeight)
  left: 0
  right: 0
  bottom: 0
  @include flex
  justify-content: center
  :global(.modal)
    position: absolute
    width: 90%
    background: var(--modal_bg)
    height: 100%
    z-index: 1
    box-shadow: 8px 0 4px -4px rgba(0, 0, 0, 0.2), -8px 0 4px -4px rgba(0, 0, 0, 0.2)
    overflow: auto

.modalBackdrop
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: var(--backdropBg)

.closeModal
  background: var(--modal_closeIconBg)
  border-radius: 30px
  @include flex
  justify-content: center
  width: 30px
  height: 30px
  position: absolute
  top: 5px
  right: 5px
  z-index: 2
  &:before
    content: ""
    mask-image: var(--icon-close)
    width: 10px
    height: 10px
    mask-size: 10px
    background-color: rgb(var(--secondaryColor))
  &:active
    opacity: 0.8