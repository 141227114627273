@import "mixins"
.dropdownContainer
  position: relative

  > div
    position: relative

    .arrow
      position: absolute
      width: 10px
      height: 5px
      min-width: 10px
      background: var(--icon-arrow) no-repeat
      top: calc(50% - 2px)
      right: 12px
      pointer-events: none

.dropdown
  border: 0
  border-radius: var(--base_borderRadius)
  padding: 0 28px 0 10px
  outline: none
  font-size: 14px
  color: rgb(var(--secondaryColor))
  width: 100%
  height: 36px
  background: var(--field_bg)
  appearance: none

  option
    color: rgb(var(--primaryColor))
    background: var(--ddl_listBg)
    &:checked
      background: var(--ddl_listSelectedBg)

  option:disabled
    color: rgba(var(--primaryColor), 0.7)
    text-transform: uppercase

  &::placeholder
    color: rgba(var(--primaryColor), 0.7)
    text-transform: uppercase

.error
  composes: error from "components/base/input-field/input-field.module.sass"

.fieldError
  position: relative

  &.dropdown > div
    border-color: rgb(var(--particularColor2))
