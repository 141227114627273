@import "mixins"
.menu
  background: var(--menu_bg)
  box-shadow: var(--menu_shadow)
  height: var(--menuHeight)
  position: fixed
  z-index: 1001
  bottom: 0
  left: 0
  width: 100%
  @include flex

  > button
    background: transparent
    flex: 1
    flex-direction: column
    height: 100%
    min-width: calc((100% - 90px) / 4)
    color: rgba(var(--primaryColor))
    text-align: center

    &:not(.profile) :global(.svg-icon)
      min-width: 18px
      min-height: 18px

.login
  color: var(--menu_itemActiveBaseColor) !important
  margin: var(--menu_loginMargin)
  padding: 0 4px
  min-width: var(--menu_loginActiveMinWidth) !important
  transition: var(--menu_loginTransition)

  :global(.svg-icon)
    background: var(--menu_loginUserIconBg)
    width: 28px
    height: 28px
    box-shadow: inset 0 0 0 1px var(--menu_loginUserIconShadow)
    border-radius: 100%
    margin-top: 3px
    margin-bottom: -1px
    justify-content: center

    svg
      width: 100%
      height: auto

      path
        fill: var(--menu_loginUserIconFill)

      > :not(path)
        opacity: 0.7
        filter: grayscale(100%)

.profile
  color: rgb(var(--primaryColor))
  margin: var(--menu_loginMargin)
  min-width: var(--menu_loginActiveMinWidth) !important
  padding: 0 4px

  > span
    margin-top: 3px
    margin-bottom: -1px
    position: relative
    @include flex

  :global(.svg-icon)
    background: var(--menu_profileUserIconBg)
    width: 28px
    height: 28px
    box-shadow: inset 0 0 0 1px var(--menu_profileUserIconShadow)
    border-radius: 100%
    justify-content: center
    transition: margin .16s

    svg
      width: 100%
      height: auto

  .verificationReminder
    position: absolute
    background: rgb(var(--secondaryColor))
    width: 14px
    height: 14px
    @include flex
    justify-content: center
    border-radius: 100%
    left: 20px
    bottom: 1px

    > span
      @include flex
      justify-content: center

  .unreadMsgsCount
    position: absolute
    border-radius: 2px
    @include flex
    justify-content: center
    padding: 1px 2px
    line-height: 11px
    font-size: 11px
    min-width: 16px
    left: 28px
    transform: translateX(-50%)
    top: -3px
    background: var(--menu_msgCountBg)
    color: #000
    box-shadow: 0 0 4px rgba(0,0,0,.4)

  &.loginActive
    color: rgba(var(--secondaryColor))

  &.profileDefault.loginActive
    > span
      :global(.svg-icon)
        margin-right: 8px
      &::after
        content: ""
        background-color: var(--menu_itemActiveBaseColor)
        mask-image: var(--icon-menu-double-arrow)
        width: 10px
        height: 10px
        transform: rotate(-90deg)

.loginActive
  background: var(--menu_loginActiveBg) !important
  border-radius: 0 0 6px 6px
  margin: 0 4px 7px
  height: var(--menu_loginActiveHeight) !important
  border: 0
  box-shadow: 0 1px 0 0 rgb(var(--thirdaryColor))
  position: relative
  transition: all .2s

  &:not(.profileDefault)::after
    content: ""
    background-color: var(--menu_itemActiveBaseColor)
    mask-image: var(--icon-menu-double-arrow)
    width: 10px
    height: 10px
    transform: rotate(45deg)
    position: absolute
    left: 5px
    bottom: 5px

.betslipModal
  height: calc(100% - 60px)
  top: 0
  z-index: 6
  font-size: 13px

  :global(.modal)
    width: 100%
    height: calc(100% - var(--headerHeight))
    overflow: auto
    background: var(--body_bg)
    display: flex
    flex-direction: column

.loginModalCompact :global(.modal)
  height: auto
  width: 100%
  border-radius: 8px 8px 0 0
  background: var(--login_bg)
  max-height: 98%
  --field_bg: var(--login_fieldBg)

  > div
    padding: 0 8px 50px

    > :global(.imageNext)
      height: 24px
      margin: 12px auto 6px

    > div:first-of-type
      margin: 0 -8px
      padding: 0 8px
      height: 52px

      button
        height: 40px
        font-size: 16px
        background: var(--signUpBtn_bg)
        color: var(--signUpBtn_color)
        &:active
          background: var(--signUpBtn_activeBg)

// .casinoGameModal
//   z-index: 2
//   &:has(:global(.casino-game-fullScreen))
//     height: 100%
//     top: 0
//     z-index: 1002
//   :global(.modal)
//     width: 100%
//     overflow: hidden
//     > i
//       z-index: 1004
//     > div
//       z-index: 1003
//       height: 100%
//       top: 0

.liveGameModal
  z-index: 4
  :global(.modal)
    width: 100%
    overflow: auto
    display: flex
    flex-direction: column
    background: var(--body_bg)
    border-top-left-radius: 8px
    border-top-right-radius: 8px
    border-top: 1px solid #000
    > i
      width: 40px
      height: 50px
      min-height: 50px
      top: 0
      position: sticky
      left: 100%
      border-radius: 0
      background: var(--live_gameSelectorCompactBg)
      z-index: 7
    .liveGameContainer
      min-height: var(--withoutHeaderHeight)
      margin-top: -50px

.accountModalCompact :global(.modal)
  height: auto
  width: 100%
  max-width: 486px
  border-radius: 6px 6px 0 0
  max-height: 94%
  overflow: hidden
  display: flex
  flex-direction: column
