@import "mixins"
.right
  &.not-visible
    > div:first-of-type
      opacity: 0
      transition: opacity 0.4s 0.1s
    :global(.modal)
      right: -90%
      transition: right 0.4s

  &.visible
    > div:first-of-type
      opacity: 1
    :global(.modal)
      right: 0

.rightFullPage
  &.not-visible
    > div:first-of-type
      opacity: 0
      transition: opacity 0.4s 0.1s
    :global(.modal)
      right: -100%
      transition: right 0.4s
      width: 100%

  &.visible
    > div:first-of-type
      opacity: 1
    :global(.modal)
      right: 0

.left
  &.not-visible
    > div:first-of-type
      opacity: 0
      transition: opacity 0.4s 0.1s
    :global(.modal)
      left: -90%
      transition: left 0.4s

  &.visible
    > div:first-of-type
      opacity: 1
    :global(.modal)
      left: 0

.opacity
  &.not-visible
    opacity: 0
    transition: opacity 0.8s cubic-bezier(0.19, 1, 0.21, 1)

  &.visible
    opacity: 1

.modalBaseAnimate
  &.not-visible:global
    > div:first-of-type
      opacity: 0
      transition: opacity 0.4s 0.1s
    .modal
      opacity: 0
      top: -20%
      transition: opacity 0.6s, top 1s

  &.visible
    > div:first-of-type
      opacity: 1
    :global(.modal)
      opacity: 1
      top: 50%
      transform: translateY(-50%)

.bottom
  &.not-visible
    > div:first-of-type
      opacity: 0
      transition: opacity 0.4s 0.1s
    :global(.modal)
      bottom: -100%
      transition: bottom 0.4s

  &.visible
    > div:first-of-type
      opacity: 1
    :global(.modal)
      bottom: 0

.height
  transition: all 0.4s
  &.not-visible
    height: 0
    overflow: hidden
