@import "mixins"
.infoTooltip
  position: absolute
  z-index: 5
  padding: 8px
  //max-width: 203px;
  width: 100%
  line-height: 13px
  background: #363c44
  color: rgb(var(--secondaryColor))
  border-top: 1px solid rgb(var(--particularColor2))
  border-bottom-left-radius: 3px
  border-bottom-right-radius: 3px
  font-size: 11px
  font-weight: normal
  margin-top: 2px
  box-shadow: 0 1px 3px rgba(#191f25, 0.2)
  &:before
    content: ""
    width: 0
    height: 0
    border-style: solid
    border-width: 0 3px 4px 3px
    border-color: transparent transparent rgb(var(--particularColor2)) transparent
    position: absolute
    top: -5px